import React from "react";
import { Tag, Button, Typography, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

const { Title } = Typography;

export const MakesFiltersAndSelectionComponent = (props) => {
  const { onClearSearcheAndSelection, selectedMake } = props;
  const isMobile = useIsMobile();
  return (
    <>
      {selectedMake ? (
        <div
          style={{ marginTop: "2%", textAlign: isMobile ? "center" : "left" }}
        >
          <Title level={5}>Filters & Selections:</Title>
          <Tag color="processing" visible={!selectedMake ? false : true}>
            {`Selected Make: ${!selectedMake ? "none" : selectedMake.name}`}
          </Tag>
          <Button
            type="link"
            icon={<CloseOutlined />}
            size="small"
            onClick={onClearSearcheAndSelection}
          >
            Clear Search/Filter
          </Button>
          <Divider />
        </div>
      ) : null}
    </>
  );
};
