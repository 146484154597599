import React from "react";
import { Tag, Button, Typography, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const { Title } = Typography;

export const ModelsFilterAndSelectionComponent = (props) => {
  const { onClearSearcheAndSelection, selectedModel, filteredDataset } = props;
  return (
    <>
      {selectedModel ? (
        <div style={{ marginTop: "2%" }}>
          <Title level={5}>Filters & Selections:</Title>
          <Tag color="processing" visible={!selectedModel ? false : true}>
            {`Selected Model: ${
              !selectedModel
                ? "none"
                : selectedModel.name + "-" + selectedModel.trim
            }`}
          </Tag>
          <Button
            type="link"
            icon={<CloseOutlined />}
            size="small"
            onClick={onClearSearcheAndSelection}
          >
            Clear Search/Filter
          </Button>
          <Divider />
        </div>
      ) : null}
    </>
  );
};
