import React from "react";
import { Typography, Tag, Button, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const { Title } = Typography;

export const BulkUploaderFiltersAndSelectionComponent = (props) => {
  const { searchValue, onRemoveAllSearches } = props;
  return (
    <>
      {searchValue ? (
        <>
          <Divider />
          <Title level={5}>Filters & Selections:</Title>
          <Tag color="processing" visible={!searchValue ? false : true}>
            {`Search Via: ${!searchValue ? "none" : searchValue}`}
          </Tag>
          <Button
            type="link"
            icon={<CloseOutlined />}
            size="small"
            onClick={onRemoveAllSearches}
          >
            Clear Search
          </Button>
        </>
      ) : null}
    </>
  );
};
