import React from "react";
import { Tag, Button, Typography, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

const { Title } = Typography;

export const SpecialistFiltersAndSelectionComponent = (props) => {
  const {
    onClearSearcheAndSelection,
    selectedSpecialist,
    searchValue,
    onSearchClose,
  } = props;

  const isMobile = useIsMobile();
  return (
    <>
      {selectedSpecialist || searchValue ? (
        <div
          style={{ marginTop: "2%", textAlign: isMobile ? "center" : "left" }}
        >
          <Divider />
          <Title level={5}>Filters & Selections:</Title>
          <Tag color="processing" visible={!selectedSpecialist ? false : true}>
            {`Selected Adviser: ${
              !selectedSpecialist ? "none" : selectedSpecialist.email
            }`}
          </Tag>
          <Tag
            hidden={searchValue ? false : true}
            color="processing"
          >{`Adviser Containing: "${searchValue}"`}</Tag>
          <Button
            type="link"
            icon={<CloseOutlined />}
            size="small"
            onClick={onClearSearcheAndSelection}
          >
            Clear Search/Filter
          </Button>
        </div>
      ) : null}
    </>
  );
};
