import React from "react";
import { Modal, Form, message } from "antd";
import { useUpdate } from "../../../hooks/useUpdate";
import { WarehouseGenralInformationFormComponent } from "./WarehouseGenralInformationFormComponent";
import useFetchWarehouses from "../../../hooks/warehouses/useFetchWarehouses";

export const WarehouseUpdateComponent = ({
  warehouseId,
  updateModalVisibility,
  handleUpdateModalVisibility,
  specialists,
  banks,
  onClickShowMoreCity,
  onClickShowMoreBanks,
  setCurrentPagination,
  onClearSelection,
  form,
  onChangeCity,
  onChangeProvince,
  onChangeBarangay,
  provinces,
  isProvinceFetchingLoading,
  cities,
  isCityFetchingLoading,
  barangays,
  isBarangayFetchLoading,
  onClickShowMoreAdviser,
  oldCities = [],
}) => {
  const { onPaginationChange, query } = useFetchWarehouses();
  const HandleUpdateWarehouse = async () => {
    const payload = await form.validateFields();
    const { address } = payload;

    const _payload = {
      bank_id: payload.bank_id,
      direction_here: payload.direction_here,
      landmark: payload.landmark,
      days_avail: payload.days_avail,
      days_open: payload.days_avail,
      hours_open: payload.hours_open,
      viewing_address: payload.viewing_address,
      ...address,
    };

    const resp = await useUpdate(
      `${process.env.REACT_APP_API_URL}/warehouses/${warehouseId}`,
      _payload
    );

    form.resetFields();

    if (resp.status === 200) {
      message.success("Successfuly Updated Warehouse General Information");
      handleUpdateModalVisibility();
      setCurrentPagination(1);
      onClearSelection();
      onPaginationChange(query?.page, query?.total);
      return;
    }
  };

  return (
    <>
      <Modal
        visible={updateModalVisibility}
        title="Update Warehouse"
        okText="Update"
        cancelText="Cancel"
        onCancel={handleUpdateModalVisibility}
        centered={true}
        width={500}
        destroyOnClose={true}
        onOk={HandleUpdateWarehouse}
      >
        <Form form={form} layout="vertical" name="warehouses">
          <WarehouseGenralInformationFormComponent
            banks={banks}
            provinces={provinces}
            isProvinceFetchingLoading={isProvinceFetchingLoading}
            isCityFetchingLoading={isCityFetchingLoading}
            isBarangayFetchingLoading={isBarangayFetchLoading}
            cities={cities}
            oldCities={oldCities}
            barangays={barangays}
            specialists={specialists}
            onClickShowMoreCity={onClickShowMoreCity}
            onClickShowMoreBanks={onClickShowMoreBanks}
            onClickShowMoreAdviser={onClickShowMoreAdviser}
            onChangeProvince={onChangeProvince}
            onChangeCity={onChangeCity}
            onChangeBarangay={onChangeBarangay}
          />
        </Form>
      </Modal>
    </>
  );
};
